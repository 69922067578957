import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import {
  ShouldRevalidateFunction,
  useCatch,
  useLoaderData,
} from "@remix-run/react";
import {
  HeadersFunction,
  LoaderFunction,
  MetaFunction,
  redirect,
} from "@remix-run/server-runtime";
import { Fragment } from "react";
import invariant from "tiny-invariant";
import FourOFour, { FourOFourCaughtData } from "~/components/shared/FourOFour";
import SchemaOrg from "~/components/utils/SchemaOrg";
import { getBlackPage } from "~/components/utils/getBlackPage";
import { getFrontifyImageURL } from "~/components/utils/getFrontifyImage";
import {
  SchemaOrgType,
  getPageSchema,
} from "~/components/utils/schemaOrgUtils.server";
import { SitemapHandle } from "~/seo/sitemap.server";
import {
  createDocumentFromSection,
  getComponentFromContentfulRichTextEntry,
  getSections,
} from ".";
import type {
  IInsight,
  IPage,
  SpecificLocale,
} from "../@types/generated/contentful";
import { getEnv } from "../config";
import {
  ComponentDataProvider,
  PageComponentData,
  getPageComponentData,
} from "../contexts/ComponentDataProvider";
import { i18n } from "../i18n.server";
import { doesRouteExistOnSiteMap } from "../other-routes.server";
import {
  getAllEntriesOfType,
  getEntryBySlug,
  getPageBySlug,
} from "./index.server";

type OpenGraphType = "website" | "insight";
export const previewSearchParamKey = "preview";
const openGraphDefaultType = "website";

let maxAge = 300;
if (process.env.NODE_ENV === "development") {
  maxAge = 0;
}

export const headers: HeadersFunction = () => {
  return {
    "Cache-Control": `public, s-max-age=${maxAge}`,
  };
};

export const handle = (
  content_type: string,
  baseRoute?: string
): SitemapHandle => ({
  id: "contentful",
  getSitemapEntries: async (request: Request) => {
    /**
     *  If you have a lot of content, this may take a while.
     * You may want to limit the number of entries we fetch.
     */
    try {
      const operators: { [k: string]: string | boolean | string[] } = {};
      const select = ["fields.slug", "sys.updatedAt", "fields.embargoed"];
      if (content_type === "page") {
        select.push("fields.slugPrefix");
        operators["fields.embargoed[ne]"] = true;
      }
      if (content_type === "insight") {
        select.push("fields.topic", "fields.embargoed");
        // https://www.contentful.com/developers/docs/javascript/tutorials/using-js-cda-sdk/
        operators["fields.embargoed[ne]"] = true;
      }

      const locale = await i18n.getLocale(request);
      const entries = (await getAllEntriesOfType({
        content_type,
        locale,
        otherParams: {
          select,
          ...operators,
        },
      })) as SpecificLocale<any>[];

      // Filter out any entries that don't have the required fields
      const validEntries = entries.filter((entry) => entry && entry.fields);

      return validEntries.map((entry) => {
        let slugPrefix = entry.fields.slugPrefix
          ? `${entry.fields.slugPrefix}/`
          : "";

        if (content_type === "insight" && entry.fields.topic?.fields?.slug) {
          slugPrefix = `${entry.fields.topic.fields.slug}/`;
        }

        if (content_type === "expert") {
          slugPrefix = "experts/";
        }

        const base = `${baseRoute ? baseRoute + "/" : ""}`;
        return {
          route: `/${base}${slugPrefix}${entry.fields.slug}`,
          changefreq: "weekly",
          lastmod: entry.sys.updatedAt,
          priority: 0.5,
        };
      });
    } catch (error) {
      if (error) {
        console.error("An error occurred while fetching entries:", error);
      }

      return [];
    }
  },
});

export interface MetaInfo {
  title: string;
  description: string;
  openGraphUrl: string;
  openGraphType: OpenGraphType;
  openGraphImageUrl: string;
  robots?: string;
}

export const meta: MetaFunction = ({ data }: { data: { meta: MetaInfo } }) => {
  return {
    title: data?.meta?.title,
    description: data?.meta?.description,
    "og:title": data?.meta?.title,
    "og:description": data?.meta?.description,
    "og:url": data?.meta?.openGraphUrl,
    "og:type": data?.meta?.openGraphType,
    "og:image": data?.meta?.openGraphImageUrl,
    // "og:image:alt": data?.meta?.openGraphImageUrl,
    "twitter:title": data?.meta?.title,
    "twitter:card": "summary_large_image",
    "twitter:description": data?.meta?.description,
    "twitter:image": data?.meta?.openGraphImageUrl,
    "twitter:image:src": data?.meta?.openGraphImageUrl,
    robot: data?.meta?.robots ? data.meta.robots : "index, follow",
  };
};

export function getMetaInfo({
  title,
  description,
  requestUrl,
  openGraphType,
  openGraphImageUrl,
}: {
  title: string;
  description: string;
  requestUrl: string;
  openGraphType?: OpenGraphType;
  openGraphImageUrl?: string;
}): MetaInfo {
  const url = new URL(requestUrl);
  const origin = getEnv("PUBLICLY_AVAILABLE_ORIGIN");
  return {
    title,
    description,
    openGraphUrl: `${origin}${url.pathname}`,
    openGraphType: openGraphType || openGraphDefaultType,
    openGraphImageUrl: openGraphImageUrl
      ? openGraphImageUrl
      : `${origin}/favicon.png`,
  };
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
  currentUrl,
  nextUrl,
}) => {
  /**
   * Update this logic if you need the loader to re-run
   * When something other than the pathname changes (e.g. search params)
   */
  if (currentUrl.pathname === nextUrl.pathname) {
    return false;
  }
  return defaultShouldRevalidate;
};

export interface ContentfulPageLoaderData {
  page: SpecificLocale<IPage>;
  componentData: PageComponentData;
  meta: MetaInfo;
  schemaOrg: SchemaOrgType;
}

export function getContentfulPageLoader(slug?: string) {
  const contentfulPageLoader: LoaderFunction = async ({
    params,
    request,
  }): Promise<ContentfulPageLoaderData | Response> => {
    const url = new URL(request.url);

    if (doesRouteExistOnSiteMap(url.pathname)) {
      // because this is called for every route, we'll do an early return for anything that has a other route setup. The response will be handled there.
      return new Response();
    }
    const invariantMessage =
      "expected slug to be passed as an argument or params.slug to be defined";
    const slugToQuery = slug || params.slug || params.topicSlug;

    invariant(slugToQuery, invariantMessage);

    const locale = await i18n.getLocale(request);
    const page = await getPageBySlug(slugToQuery, {
      locale,
    });

    if (!page) {
      throw new Response(
        // This is me cheating as a way to pass the color through
        JSON.stringify({
          message: "Page Not Found",
          backgroundColor: "white",
          notFound: "page",
        } as FourOFourCaughtData),
        { status: 404 }
      );
    }

    const componentData = await getPageComponentData({
      page,
      locale,
      params,
      request,
    });

    const searchParams = new URLSearchParams({
      fm: "jpg",
    });

    const contentfulImageUrl = page.fields.openGraphImage?.fields.file.url; //TODO: this is a fallback url that will eventually be phased out

    const openGraphImageUrl = page.fields.opengraphFrontifyImage?.fields
      .frontifyImage
      ? getFrontifyImageURL(page.fields.opengraphFrontifyImage?.fields, 1200)
      : `https:${
          page.fields.opengraphFrontifyImage?.fields?.image?.fields?.file
            ?.url || contentfulImageUrl
        }?${searchParams.toString()}`;

    return {
      page,
      componentData,
      meta: getMetaInfo({
        title: page.fields.title,
        description: page.fields.description,
        requestUrl: request.url,
        openGraphImageUrl,
      }),
      schemaOrg: getPageSchema(page),
    };
  };
  return contentfulPageLoader;
}

export function ContentfulPageComponent() {
  const { componentData, schemaOrg } =
    useLoaderData<ContentfulPageLoaderData>();
  const { page } = componentData;
  const colorFirstComponent =
    componentData.page?.fields?.body?.content[0]?.data?.target?.fields
      ?.colorPalette?.fields?.backgroundColor;

  return (
    <ComponentDataProvider componentData={componentData}>
      <SchemaOrg content={schemaOrg} />
      {page &&
        page.fields.body &&
        getSections(page.fields.body as ContentfulDocument).map(
          (section, index) => {
            const component = documentToReactComponents(
              createDocumentFromSection(section),
              getComponentFromContentfulRichTextEntry
            );
            if (section.type === "inset") {
              return (
                <div key={index} className="global-container">
                  {component}
                </div>
              );
            } else {
              // Spacer for the nav
              return (
                <Fragment key={JSON.stringify(section.nodes)}>
                  {index === 0 && (
                    <section
                      className="nav-color h-mobile-nav lg:h-desktop-nav"
                      style={{
                        backgroundColor: getBlackPage(page.fields.slug)
                          ? "black"
                          : colorFirstComponent,
                      }}
                    />
                  )}
                  {component}
                </Fragment>
              );
            }
          }
        )}
    </ComponentDataProvider>
  );
}

export function ContentfulCatchBoundary() {
  const caught = useCatch();

  const data = JSON.parse(caught.data) as FourOFourCaughtData;

  if (caught.status === 404) {
    return (
      <FourOFour
        whatsNotFound={data.notFound}
        backgroundColor={data.backgroundColor}
      />
    );
  }

  throw new Error(`Unexpected caught response with status: ${caught.status}`);
}

export function ContentfulErrorBoundary({ error }: { error: any }) {
  console.error(error);
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <p className="text-xl">An error occurred</p>
      {process.env.NODE_ENV !== "production" && (
        <pre className="text-sm text-gray-500">
          <code>{error.message}</code>
        </pre>
      )}
    </div>
  );
}

export const oldSlugRedirectLoader: LoaderFunction = async ({
  params,
  request,
}) => {
  const locale = await i18n.getLocale(request);

  const invariantMessage =
    "expected slug to be passed as an argument or params.slug to be defined";
  const slugToQuery = params.slug;

  invariant(slugToQuery, invariantMessage);

  const requestUrls = request.url.split("/");
  if (
    requestUrls[requestUrls.length - 2] === "tony-blair" &&
    slugToQuery === "latest"
  ) {
    throw redirect("/experts/tony-blair");
  }

  const insight = (await getEntryBySlug("insight", slugToQuery, {
    locale,
  })) as SpecificLocale<IInsight>;

  if (!insight) {
    throw redirect(`/insights/news/${slugToQuery}`, 301);
  }

  throw redirect(
    `/insights/${insight.fields.topic.fields.slug}/${slugToQuery}`,
    301
  );
};
